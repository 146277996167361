// helpers usados para definir o conteúdo da tab Resumo como em Lead_Detail
import {
  resumoCC,
  resumoCH,
  resumoSS,
} from '../LeadDetailData/contentStructure.js';

//Tabs Crédito Consolidado:
const contentCC = () => {
  let items = [
    {
      tab: 'Resumo',
      desc: 'obs',
      content: resumoCC(),
      text: [],
      secondText: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: '1º Titular',
      desc: 'prit',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: '2º Titular',
      desc: 'sect',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: 'Operação',
      desc: 'operation_data',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: 'Responsabilidades',
      desc: 'responsabilities',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: 'Parecer',
      desc: 'comercial_assessment',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: 'Documentos',
      desc: 'docs',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: 'Owner',
      desc: 'owner',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
  ];

  return items;
};

//Seguro de vida
const contentSS = () => {
  let items = [
    {
      tab: 'Resumo',
      desc: 'obs_ss',
      content: resumoSS(),
      secondRow: [],
      text: [],
      secondText: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: '1º Titular',
      desc: 'prit_ss',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: 'Restantes Titulares',
      desc: 'sect_ss',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },

    {
      tab: 'Seguros',
      desc: 'seguros',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: 'Documentos',
      desc: 'docs',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: 'Owner',
      desc: 'owner',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
  ];

  return items;
};

//Tabs Crédito Pessoal
const contentCP = () => {
  let items = [
    {
      tab: 'Resumo',
      desc: 'obs',
      content: resumoCC(),
      secondRow: [],
      text: [],
      secondText: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: '1º Titular',
      desc: 'prit',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: '2º Titular',
      desc: 'sect',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: 'Operação',
      desc: 'operation_data',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: 'Responsabilidades',
      desc: 'responsabilities',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: 'Parecer',
      desc: 'comercial_assessment',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: 'Documentos',
      desc: 'docs',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    { tab: 'Owner', desc: 'owner', content: [], data: [], family: [] },
  ];

  return items;
};

//Tabs Crédito de Habitação:
const contentCH = () => {
  let items = [
    {
      tab: 'Resumo',
      desc: 'obs_ch',
      content: resumoCH(),
      secondRow: [],
      text: [],
      secondText: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: '1º Titular',
      desc: 'prit_ch',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: '2º Titular',
      desc: 'sect_ch',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: 'Operação',
      desc: 'operation_data_ch',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: 'Banco',
      desc: 'obs_bank',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: 'Avaliação',
      desc: 'avaliacao',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: 'Escritura',
      desc: 'escritura',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    {
      tab: 'Documentos',
      desc: 'docs',
      content: [],
      secondRow: [],
      data: [],
      family: [],
      familySecondRow: [],
    },
    { tab: 'Owner', desc: 'owner', content: [], data: [], family: [] },
  ];

  return items;
};

export { contentCH, contentCC, contentSS, contentCP };
