//Fabric of types:
class Input {
  constructor(title, family, value, type, options, level) {
    this.title = title;
    this.family = family;
    this.value = value;
    this.type = type;
    this.options = options;
    this.level = level;
    this.menu_data_nasc = false;
    this.menu_hour = false;
    this.cols = 12;
  }

  //static:
  //arr já ordenado
  //selected;
  static cols(arr) {
    //Quantas famílias a array tem:
    let amountFamily = arr
      .map((e) => e.family)
      .filter(onlyUnique)
      .filter((e) => e != undefined); //ok

    let newArray = [];

    for (let i in amountFamily) {
      //Array apenas para a familia
      let familyArr = arr.filter((e) => e.family == amountFamily[i]);

      familyArr = colsFiltered(familyArr);

      newArray = newArray.concat(familyArr);
    }

    return { newArray, amountFamily };
  }
}

//Função para separar os valores unicos:
const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

const colsFiltered = (arr) => {
  //Index com level igual à 1
  let index1 = arr.findIndex((e) => e.level == 1); //ok

  //Separar o bloco além do tipo 01 (que será apenas nome);
  let finalLenght = arr.length - (index1 + 1);
  let numberOfblocks = 0;
  let conditional = false;
  let block = finalLenght < 5 ? finalLenght : 5; //quantidade de inputs por blocos iguais
  let finalBlock = 0; //quantidade de inputs do bloco final

  //Define a melhor separação por blocos:
  while (!conditional) {
    numberOfblocks = Math.ceil(finalLenght / block);
    finalBlock = finalLenght - (numberOfblocks - 1) * block;

    if ((finalBlock <= 6 && finalBlock >= 3) || finalBlock == finalLenght) {
      conditional = true;
    } else block--;
  }

  //Nova Array sem o tipo 01:
  let newArraywithoutType01 = JSON.parse(JSON.stringify(arr)).slice(
    index1 + 1,
    arr.length
  );

  let newArraywithType01 = JSON.parse(JSON.stringify(arr)).slice(0, index1 + 1);

  newArraywithType01.forEach((x) => {
    if (x.level == 1) x.cols = 10;
    else x.cols = 2;
  });

  //dividir por colunas:
  //Tratar os blocos iguais:
  for (let i = 0; i < numberOfblocks - 1; i++) {
    //Index
    //Real index beg and real last +1
    let beginning = i * block;
    let last = block * i + block;

    //Primeira metade:
    newArraywithType01 = newArraywithType01.concat(
      rearrangeCols(beginning, last, newArraywithoutType01)
    );
  }

  //Tratar o ultimo bloco:
  let beginning = (numberOfblocks - 1) * block; //0 //6
  let last = newArraywithoutType01.length;

  //Primeira metade:
  newArraywithType01 = newArraywithType01.concat(
    rearrangeCols(beginning, last, newArraywithoutType01)
  );

  return newArraywithType01;
};

const rearrangeCols = (beginning, last, arr) => {
  let start = beginning;

  let arrayCopyHalf = JSON.parse(JSON.stringify(arr));

  arrayCopyHalf = arrayCopyHalf.slice(beginning, last);

  let middleOfHalf = arrayCopyHalf.length;
  let valueCols = Math.floor(12 / middleOfHalf); //2

  //Arrumar o número de colunas conforme o owner:

  if (
    arrayCopyHalf.length > 0 &&
    (Object.values(arrayCopyHalf[0]).includes('owner') ||
      Object.values(arrayCopyHalf[0]).includes('comercial_assessment') ||
      Object.values(arrayCopyHalf[0]).includes('banco'))
  ) {
    valueCols = 12;
  }

  let type02 = arrayCopyHalf.filter((e) => e.level == 2).length;
  let type03 = arrayCopyHalf.filter((e) => e.level == 3).length;

  let colType02 = valueCols;

  let conditional = false;

  // //rearrange
  if (type02 >= 1 && middleOfHalf > 1) {
    while (!conditional) {
      valueCols = (12 - colType02 * type02) / (middleOfHalf - type02);

      let validation = type02 * colType02 + type03 * valueCols == 12;

      if (validation) {
        conditional = true;
      } else colType02++;
    }
  }

  let decrement = 0;
  let leftOverCols = 12 - valueCols * type03; //Sobra de colunas

  //Equilíbrio:
  if (type02 == 0 && middleOfHalf > 1 && leftOverCols > 0) {
    for (
      let j = middleOfHalf + start - 1;
      j >= arrayCopyHalf.length + start - leftOverCols;
      j--
    ) {
      arr[j].cols = valueCols + 1;
    }

    decrement = leftOverCols;
  }

  for (let j = start; j < arrayCopyHalf.length + start - decrement; j++) {
    if (arr[j].level == 3) arr[j].cols = valueCols;
    else arr[j].cols = colType02;
  }

  return arr.slice(start, last);
};

export { Input };
