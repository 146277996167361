const leadComplete = {
  prit_data: {
    titulo: '',
    nome: '',
    estado_civil: '',
    data_nascimento: '',
    idade: '',
    telefone: '',
    email: '',
    nacionalidade: '',
    naturalidade: '',
    tipo_de_documento: '',
    numero_do_documento: '',
    validade_do_documento: '',
    nif: '',
    dependentes: '',
    entidade_patronal: '',
    empregado_desde: '',
    vinculo: '',
    profissao: '',
    sector: '',
    rendimento_liquido_mensal: '',
    rendimentos_variaveis: '',
    rendimentos_prediais: '',
    pensao_de_alimentos: '',
    rendimentos_nao_comprovaveis: '',
    outros_rendimentos: '',
    proveniencia_de_outros_rendimentos: '',
    iban: '',
    ano_de_abertura_da_conta: '',
    tipo_de_habitacao: '',
    morada: '',
    codigo__postal: '',
    localidade: '',
    vive_nesta_casa_desde: '',
    residencia: '',
    incumprimento: '',
    titulares_total: '',
    comment: ''
  },
  sect_data: {
    titulo: '',
    nome: '',
    estado_civil: '',
    data_nascimento: '',
    idade: '',
    telefone: '',
    email: '',
    nacionalidade: '',
    naturalidade: '',
    tipo_de_documento: '',
    numero_do_documento: '',
    validade_do_documento: '',
    nif: '',
    dependentes: '',
    entidade_patronal: '',
    empregado_desde: '',
    vinculo: '',
    profissao: '',
    sector: '',
    rendimento_liquido_mensal: '',
    rendimentos_variaveis: '',
    rendimentos_prediais: '',
    pensao_de_alimentos: '',
    rendimentos_nao_comprovaveis: '',
    outros_rendimentos: '',
    proveniencia_de_outros_rendimentos: '',
    iban: '',
    ano_de_abertura_da_conta: '',
  },
  operation_data: {
    operacao: '',
    prazo_pretendido: '',
    valor_do_financiamento___em_divida: '',
    adesao_ao_seguro: '',
    liquidez_adicional: '',
    data_da_contratacao: '',
    spread_actual: '',
    valor_do_multiopcoes: '',
    tipo_de_operacao: '',
    fiador: '',
  },
  responsabilities: {
    renda___prestacao_ch_1t: '',
    pensao_de_alimentos_1t: '',
    renda___prestacao_ch_2t: '',
    pensao_de_alimentos_2t: '',
    resp: '',
  },
  comercial_assessment: '',
  observations: '',
  owner: '',
  escritura: {
    data_da_escritura: '',
    hora_da_escritura: '',
    local_da_escritura: '',
    envio_de_convite: '',
    banco_principal_escritura: '',
    agencia: '',
    valor_escritura: '',
    avaliacao_do_imovel: '',
    valor_do_orcamento: '',
    outros_creditos: '',
    localizacao_imovel: '',
    tipo_de_operacao_escritura: '',
    montante_a_consolidar___financiar: '',
    prazo_pretendido_escritura: '',
  },
  avaliacao: {
    resultado_da_avaliacao: '',
    suficiente: '',
  },
  seguros: {
    seguradora_atual: '',
    premio_atual: '',
    capital_em_divida: '',
    spread_actual_seguradora: '',
    produto_seguradora: '',
    seguradora_futura: '',
    premio_proposto: '',
    banco_principal: '',
    data_de_duracao_do_emprestimo: '',
    n_de_pessoas_asseguradas: '',
    data_da_carta_de_conducao: '',
    matricula_do_carro: '',
    dependentes__seguros: '',
    marca_do_automovel: '',
    modelo_do_automovel: '',
    versao: '',
    tipo_de_cobertura: '',
    cobertura_pretendida: '',
    morada_completa_seguros: '',
    tipo_de_imovel: '',
    ano_de_construcao: '',
    area_bruta_privativa: '',
    n_de_casas_de_banho: '',
    n_de_assoalhos: '',
    data_de_nascimento_seguro: '',
    valor_a_segurar: '',
  },
  banco: [],
  text: {
    titulo: '',
    nome: '',
    estado_civil: '',
    data_nascimento: '',
    idade: '',
    telefone: '',
    email: '',
    nacionalidade: '',
    naturalidade: '',
    tipo_de_documento: '',
    numero_do_documento: '',
    validade_do_documento: '',
    nif: '',
    dependentes: '',
    entidade_patronal: '',
    empregado_desde: '',
    vinculo: '',
    profissao: '',
    sector: '',
    rendimento_liquido_mensal: '',
    rendimentos_variaveis: '',
    rendimentos_prediais: '',
    pensao_de_alimentos: '',
    rendimentos_nao_comprovaveis: '',
    outros_rendimentos: '',
    proveniencia_de_outros_rendimentos: '',
    iban: '',
    ano_de_abertura_da_conta: '',
    tipo_de_habitacao: '',
    morada: '',
    codigo__postal: '',
    localidade: '',
    vive_nesta_casa_desde: '',
    residencia: '',
    seguradora_atual: '',
    seguradora_futura: '',
    premio_atual: '',
    produto_seguradora: '',
    banco_principal: '',
    data_de_duracao_do_emprestimo: '',
    capital_em_divida: '',
    spread_actual_seguradora: '',
    data_da_carta_de_conducao: '',
    matricula_do_carro: '',
    resultado_da_avaliacao: '',
    suficiente: '',
    data_da_escritura: '',
    hora_da_escritura: '',
    local_da_escritura: '',
    envio_de_convite: '',
    banco_principal_escritura: '',
    agencia: '',
    valor_escritura: '',
    avaliacao_do_imovel: '',
    valor_do_orcamento: '',
    outros_creditos: '',
    localizacao_imovel: '',
    tipo_de_operacao_escritura: '',
    montante_a_consolidar___financiar: '',
    prazo_pretendido_escritura: '',
    renda___prestacao_ch_1t: '',
    pensao_de_alimentos_1t: '',
    renda___prestacao_ch_2t: '',
    pensao_de_alimentos_2t: '',
    resp: '',
    operacao: '',
    prazo_pretendido: '',
    valor_do_financiamento___em_divida: '',
    adesao_ao_seguro: '',
    liquidez_adicional: '',
    data_da_contratacao: '',
    spread_actual: '',
    valor_do_multiopcoes: '',
    tipo_de_operacao: '',
    fiador: '',
    incumprimento: '',
    titulares_total: '',
    comment: ''
  },
};

export { leadComplete };
