import { groupLeadDetailsHelper } from '@/helpers/LeadInsertData/formContent.js';

import {
  resumoCC,
  resumoCH,
  resumoSS,
} from '../LeadDetailData/contentStructure.js';

const populateItemstHelper = (type) => {
  switch (type) {
    case 'CH':
      return resumoCH();
    case 'CC':
      return resumoCC();
    case 'CP':
      return resumoCC();
    case 'SS':
      return resumoSS();
    default:
      return resumoCC();
  }
};

const populateContentHelper = (type, lead) => {
  let array = populateItemstHelper(type);
  let dados = JSON.parse(JSON.stringify(lead));
  let data = groupLeadDetailsHelper(dados);

  for (let item of array) {
    item.content = data[item.desc];
  }

  return array;
};

export { populateContentHelper };
