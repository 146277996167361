//Crédito Consolidado
const resumoCC = () => {
  let items = [
    {
      cols: '12',
      title: 'Geral',
      desc: 'geral',
      content: [],
    },
    {
      cols: '12',
      title: 'Dados Primeiro Titular',
      desc: 'prit_data',
      content: [],
    },
    {
      cols: '12',
      title: 'Dados Segundo Titular',
      desc: 'sect_data',
      content: [],
    },
    { cols: '12', title: 'Operações', desc: 'operation_data', content: [] },
    {
      cols: '12',
      title: 'Responsabilidades',
      desc: 'responsabilities',
      content: [],
    },
  ];

  return items;
};

//Crédito de Habitação
const resumoCH = () => {
  let items = [
    {
      cols: '12',
      title: 'Geral',
      desc: 'geral',
      content: [],
    },
    {
      cols: '12',
      title: 'Dados Primeiro Titular',
      desc: 'prit_data',
      content: [],
    },
    {
      cols: '12',
      title: 'Dados Segundo Titular',
      desc: 'sect_data',
      content: [],
    },
    { cols: '12', title: 'Operação', desc: 'operation_data', content: [] },
    { cols: '12', title: 'Avaliação', desc: 'avaliacao', content: [] },
    { cols: '12', title: 'Escritura', desc: 'escritura', content: [] },
  ];
  return items;
};

//Seguro
const resumoSS = () => {
  let items = [
    {
      cols: '12',
      title: 'Geral',
      desc: 'geral',
      content: [],
    },
    {
      cols: '12',
      title: 'Dados Primeiro Titular',
      desc: 'prit_data',
      content: [],
    },
    {
      cols: '12',
      title: 'Dados Segundo Titular',
      desc: 'sect_data',
      content: [],
    },
    { cols: '12', title: 'Seguros', desc: 'seguros', content: [] },
  ];
  return items;
};

export { resumoCC, resumoCH, resumoSS };
