//Ordem dos campos - inputs

//CC:
const text_obs_cc = [
  'nome',
  'email',
  'telefone',
  'produto',
  'montante',
  'prazo',
  'capitais_proprios',
  'modalidade',
];

const titular_cc = [
  'titulo',
  'nome',

  'estado_civil',
  'data_nascimento',
  'idade',
  'telefone',
  'email',

  'nacionalidade',
  'naturalidade',
  'n_de_dependentes',

  'nif',
  'tipo_de_documento',
  'numero_do_documento',
  'validade_do_documento',

  'entidade_patronal',
  'empregado_desde',
  'profissao',
  'vinculo',
  'sector',

  'rendimento_liquido_mensal',
  'rendimentos_variaveis',
  'rendimentos_prediais',
  'pensao_de_alimentos',
  'rendimento_nao_comprovaveis',
  'outros_rendimentos',
  'proveniencia_de_outros_rendimentos',
  'despesas_do_agregado',
  'outros_creditos___rendimento',
  'titulares_total',
  'incumprimento',
  'comment',

  'iban',
  'ano_de_abertura_da_conta',

  'tipo_de_habitacao',
  'morada',
  'codigo__postal',
  'localidade',
  'vive_nesta_casa_desde',
  'residencia',
];

//****************Crédito de Habitação

//Tab - Resumo
const text_obs_ch = [
  'titulo',
  'nome',
  'email',
  'telefone',

  // 'data_nascimento',
  // 'idade',
  'rendimento_liquido_mensal',

  'produto',
  // 'n_de_dependentes',
  // 'profissao',
  // 'vinculo',
  // 'sector',
];

const ch_resume_credit = [
  'valor_escritura',
  'montante_a_consolidar___financiar',
  'avaliacao_do_imovel',
  'valor_do_orcamento',
  'prazo_pretendido',
  'banco_principal',
  'outros_creditos',
  'localizacao_imovel',
];

//Tab - titulares
const titular_ch = [
  'titulo',
  'nome',

  'estado_civil',
  'data_nascimento',
  'idade',
  'telefone',
  'email',

  'nacionalidade',
  'naturalidade',
  'n_de_dependentes',

  'nif',
  'tipo_de_documento',
  'numero_do_documento',
  'validade_do_documento',

  'entidade_patronal',
  'empregado_desde',
  'profissao',
  'vinculo',
  'sector',

  'rendimento_liquido_mensal',
  'rendimentos_variaveis',
  'rendimentos_prediais',
  'rendimento_nao_comprovaveis',
  'outros_rendimentos',
  'proveniencia_de_outros_rendimentos',
  'pensao_de_alimentos',
  'despesas_do_agregado',
  'outros_creditos___rendimento',

  'tipo_de_habitacao',
  'morada',
  'codigo__postal',
  'localidade',
  'vive_nesta_casa_desde',
  'residencia',
];

//Tab - Escritura:
const escritura_ch = [
  'data_da_escritura',
  'hora_da_escritura',
  'local_da_escritura',
  'banco_principal_escritura',
  'outro_banco',
  'agencia',
  'operacao',
];

const titular_ch_credito = [
  'valor_escritura',
  'montante_a_consolidar___financiar',
  'avaliacao_do_imovel',
  'valor_do_orcamento',
  'prazo_pretendido',
  'banco_principal',
  'outros_creditos',
  'localizacao_imovel',
  'operacao',
];

//Tab - Operação
const operation_data_ch = [
  'tipo_de_operacao',
  'data_da_contratacao',
  'spread_actual',
  'valor_do_multiopcoes',
  'valor_do_financiamento___em_divida',
  'fiador',
];

//********************Seguro de Vida:
const ss_resume = [
  'titulo',
  'nome',

  'produto',

  'estado_civil',
  'data_nascimento',
  'idade',
  'telefone',
  'email',

  'nacionalidade',
  'naturalidade',
  'n_de_dependentes',

  'nif',
  'tipo_de_documento',
  'numero_do_documento',
  'validade_do_documento',

  'entidade_patronal',
  'empregado_desde',
  'profissao',
  'vinculo',
  'sector',

  'seguradora_atual',
  'premio_atual',
  'capital_em_divida',
  'spread_actual_seguradora',
  'produto_seguradora',
];

//Tab - titulares
const titular_ss = [
  'titulo',
  'nome',

  'estado_civil',
  'data_nascimento',
  'idade',
  'telefone',
  'email',

  'nacionalidade',
  'naturalidade',
  'n_de_dependentes',

  'nif',
  'tipo_de_documento',
  'numero_do_documento',
  'validade_do_documento',

  'entidade_patronal',
  'empregado_desde',
  'profissao',
  'vinculo',
  'sector',

  'rendimento_liquido_mensal',
  'rendimentos_variaveis',
  'rendimentos_prediais',
  'rendimento_nao_comprovaveis',
  'outros_rendimentos',
  'proveniencia_de_outros_rendimentos',
  'pensao_de_alimentos',
  'despesas_do_agregado',
  'outros_creditos___rendimento',

  'tipo_de_habitacao',
  'morada',
  'codigo__postal',
  'localidade',
  'vive_nesta_casa_desde',
  'residencia',
];

const ss_seguros = [
  'produto_seguradora',
  'seguradora_atual',
  'premio_atual',
  'capital_em_divida',
  'spread_actual_seguradora',

  'seguradora_futura',
  'premio_proposto',

  'banco_principal',
  'data_de_duracao_do_emprestimo',
  'cobertura_pretendida',

  'n_de_pessoas_asseguradas',

  'matricula_do_carro',
  //'data_da_carta_de_conducao',
  'matricula_do_carro',
  'marca_do_automovel',
  'modelo_do_automovel',
  'versao',
  'tipo_de_cobertura',

  //Vida:
  'data_de_nascimento_seguro',
  'valor_a_segurar',

  //Multirriscos:
  'morada_completa_seguros',
  'tipo_de_imovel',
  'ano_de_construcao',
  'area_bruta_privativa',
  'n_de_casas_de_banho',
  'n_de_assoalhos',
];

//Tab resumo:
const text_obs_ss = [
  'nome',
  'email',
  'telefone',

  'produto',

  'seguradora_atual',
  'premio_atual',
  'capital_em_divida',
  'spread_actual_seguradora',
  'produto_seguradora',
];

const text_obs_ch_second = [
  'valor_escritura',
  'montante_a_consolidar___financiar',
  'avaliacao_do_imovel',
  'valor_orçamento',
  'prazo_pretendido',
  'banco_principal',
  'outros_creditos',
  'localizacao_imovel',
];

const text_obs_ss_second = [
  'seguradora_atual',
  'premio_atual',
  'produto_seguradora',
];

const operation_data_cc = [
  'operacao',
  'data_da_contratacao',
  'spread_actual',
  'valor_do_multiopcoes',
  'valor_do_financiamento___em_divida',
  'prazo_pretendido'
];

export {
  text_obs_ss_second,
  text_obs_ch_second,
  text_obs_ss,
  titular_ss,
  ss_seguros,
  ss_resume,
  titular_ch_credito,
  titular_ch,
  ch_resume_credit,
  text_obs_ch,
  text_obs_cc,
  escritura_ch,
  titular_cc,
  operation_data_cc,
  operation_data_ch,
};
